import { setMixpanelUserPropertyOnce } from "./mixpanel";

export const bucketExperimentCookieValue = <A, B>(
  valueA: A,
  valueB: B,
  split = 50,
): A | B => {
  const randomNumber = Math.floor(Math.random() * 100) + 1;

  if (randomNumber <= split) {
    return valueB;
  }

  return valueA;
};

export interface PageExperiment {
  experimentName: string;
  experimentBucket: string;
}

export const trackPageExperiments = (): PageExperiment[] => {
  const experiments =
    document.querySelectorAll<HTMLElement>("[data-experiment]");

  let pageExperiments: PageExperiment[] = [];

  experiments.forEach((experiment) => {
    if (experiment.dataset.experiment) {
      try {
        const experimentData = JSON.parse(experiment.dataset.experiment);
        const { experimentName, experimentBucket } = experimentData;
        setMixpanelUserPropertyOnce(experimentName, experimentBucket);
        pageExperiments.push({ experimentName, experimentBucket });
      } catch (error) {
        console.error("Error parsing experiment data", error);
      }
    }
  });

  return pageExperiments;
};
